export const ButtonStyles = {
  UpgradeToDownloadButton: {
    backgroundColor: "#FF4438",
    height: 40,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    borderRadius: 4,
  },

  UpgradeToDownloadText: {
    fontSize: 14,
    color: "#FFFFFF",
    fontWeight: "600",
  },

  PostOutlineButton: {
    top: -100,
    paddingTop: 10,
    paddingBottom: 10,
    alignSelf: "flex-end",
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    //  color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  PostOutlineText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },

  OutlineButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    //    color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  OutlineText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  CourseZoomButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#4A8AF7",
    borderWidth: 1,
    borderColor: "#4A8AF7",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  CourseZoomText: {
    color: "#FFFFFF",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 5,
  },
  AdminOutlineButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 29,
    paddingRight: 29,
    marginLeft: 30,
    marginRight: 30,
    //    color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  AdminOutlineText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  AdminSmallOutlineButton: {
    height: "unset",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    paddingRight: 4,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 2,
    marginBottom: 2,
    //    color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  AdminSmallOutlineText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 12,
    padding: 2,
  },
  AdminAddButton: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    paddingRight: 7,
    backgroundColor: "#F0493E",
    boxShadow: "unset",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  AdminAddText: {
    color: "#FFFFFF",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
    textAlign: "center",
    width: "100%",
  },
  AdminInviteButton: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 7,
    paddingRight: 7,
    backgroundColor: "#F0493E",
    boxShadow: "unset",
    width: "100%",
    marginTop: 10,
  },
  AdminInviteText: {
    color: "#FFFFFF",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
    textAlign: "center",
    width: "100%",
  },
  AdminModalButton: {
    backgroundColor: "#FFFFFF",
  },
  AdminModalText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  ResourceModalButton: {
    backgroundColor: "#FFFFFF",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
  },
  ResourceModalText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  ResourceModalSolidButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    width: "100%",
  },
  ResourceModalSolidText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
  ResourceModalTransparentButton: {
    paddingTop: 10,
    paddingBottom: 10,

    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    //   color:"#ffffff",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  ResourceModalTransparentText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  AdminModalOrangeButton: {
    backgroundColor: "#FFFFFF",
  },
  AdminModalOrangeText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  courseAssignmentButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  courseAssignmentText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  OutlineBoldButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 25,
    //   color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  OutlineBoldText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "bold",
  },
  OutlineSmallButton: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //    color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    paddingHorizontal: 22,
    height: 24,
  },
  OutlineSmallText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 12,
  },
  ProfileSmallButton: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //    color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    height: 36,
    alignSelf: "center",
  },
  ProfileSmallText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  SolidCreateAccountButton: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidCreateAccountText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    lineHeight: 28,
    padding: 12,
    fontWeight: "600",
  },
  SolidCreateAccountOneStoryButton: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "rgb(255,179,42)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidCreateAccountOneStoryText: {
    color: "rgb(255,179,42)",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    lineHeight: 28,
    padding: 12,
    fontWeight: "600",
  },
  SolidSignIn2Button: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 60,
    paddingRight: 60,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidSignIn2Text: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidSignIn2OneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 60,
    paddingRight: 60,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidSignIn2OneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  DisabledSignInButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#33333370",
    borderWidth: 1,
    borderColor: "#33333370",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  DisabledSignInText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidSignInOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidSignInOneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidSignInButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  SolidSignInText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidButtonDisabled: {
    backgroundColor: "#B5B5B5",
    borderWidth: 1,
    borderColor: "#B5B5B5",
  },
  SolidText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
    justifyContent: "center",
    width: "100%",
  },
  SolidOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidOneStoryButtonDisabled: {
    backgroundColor: "#B5B5B5",
    borderWidth: 1,
    borderColor: "#B5B5B5",
  },
  SolidOneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
    justifyContent: "center",
    width: "100%",
  },
  courseCardSolidButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  courseCardSolidText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidProfileDeleteButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  SolidProfileDeleteText: {
    textAlign: "center",
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidProfileDeleteOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  SolidProfileDeleteOneStoryText: {
    textAlign: "center",
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidProfileButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  SolidProfileText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidProfileOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  SolidProfileOneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidProfileNameButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  SolidProfileNameText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidAboutMeButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidAboutMeText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidAboutMeOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 10,
    //   color:"#F0493E",
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidAboutMeOneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidMapButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    zIndex: 1000,
  },
  SolidMapText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidResourcesButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    zIndex: 1000,
  },
  SolidResourcesText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidRightMarginButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidRightMarginButtonDisabled: {
    backgroundColor: "#aaaaaa",
    borderColor: "#aaaaaa",
  },
  SolidRightMarginText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidRightMarginOneStoryButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
    //   color:"#F0493E",
    backgroundColor: "rgb(255, 198, 41)",
    borderWidth: 1,
    borderColor: "rgb(255, 198, 41)",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
  },
  SolidRightMarginOneStoryButtonDisabled: {
    backgroundColor: "#aaaaaa",
    borderColor: "#aaaaaa",
  },
  SolidRightMarginOneStoryText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  TransparentButton: {
    paddingTop: 10,
    paddingBottom: 10,

    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    //   color:"#ffffff",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  CourseSideBarButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  CourseSideBarText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 20,
    lineHeight: 26,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 18,
    fontWeight: "800",
  },
  CourseSideBarFirstButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    marginTop: 100,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  CourseSideBarFirstText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 20,
    lineHeight: 26,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 18,
    fontWeight: "800",
  },
  CourseHomeButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  CourseHomeText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  CourseHomeSidebarTopButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    justifyContent: "center",
  },
  CourseHomeSidebarTopText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  CourseTransparentBoldOrangeButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
    // color:"#ffffff",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    width: "100%",
    textAlign: "center",
  },
  CourseTransparentBoldOrangeText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "bold",
  },
  TransparentNoPaddingButton: {
    marginTop: 10,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    alignSelf: "flex-start",
  },
  TransparentNoPaddingText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 5,
  },
  TransparentCourseButton: {
    marginBottom: 20,
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    alignSelf: "flex-start",
  },
  TransparentCourseText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 20,
    lineHeight: 25,
    padding: 0,
    fontWeight: "bold",
  },
  TransparentActivityCourseButton: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 20,
    backgroundColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderBottomWidth: 2,
    borderBottomColor: "#F0493E",
  },
  TransparentActivityCourseText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    lineHeight: 25,
    padding: 0,
    fontWeight: "bold",
  },
  EditButtonButton: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: "#ffffff00",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#C4CDD5",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    alignSelf: "center",
    height: 32,
    marginBottom: 50,
    marginTop: 20,
  },
  EditButtonText: {
    color: "#333333",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  courseTransparentRegularBlackButton: {
    paddingTop: 10,
    paddingBottom: 10,

    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: "#ffffff",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  courseTransparentRegularBlackText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 20,
    lineHeight: 25,
    padding: 10,
  },
  courseActivityTransparentRegularBlackButton: {
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginRight: 20,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderBottomWidth: 2,
    borderColor: "#ffffff00",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  courseActivityTransparentRegularBlackText: {
    color: "#000000",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    lineHeight: 25,
    padding: 0,
    fontWeight: "bold",
  },
  TransparentBoldBlackButton: {
    paddingTop: 10,
    paddingBottom: 10,

    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    //  color:"#ffffff",
    backgroundColor: "#ffffff",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentBoldBlackText: {
    color: "#000000",
    fontFamily: "Graphik-Bold-App",
    fontSize: 20,
    lineHeight: 25,
    padding: 10,
    fontWeight: "bold",
  },
  TransparentBoldBlackNoMarginButton: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#ffffff",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentBoldBlackNoMarginText: {
    color: "#000000",
    fontFamily: "Graphik-Bold-App",
    fontSize: 20,
    lineHeight: 25,
    padding: 10,
    fontWeight: "bold",
  },
  TransparentBoldGreyNoMarginButton: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#ffffff",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentBoldGreyNoMarginText: {
    color: "#333333",
    opacity: 0.3,
    fontFamily: "Graphik-Bold-App",
    fontSize: 20,
    lineHeight: 25,
    padding: 10,
    fontWeight: "bold",
  },
  TransparentBoldOrangeButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    // color:"#ffffff",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentBoldOrangeText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "bold",
  },

  TransparentRegularOrangeButton: {
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    // color:"#ffffff",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  TransparentRegularOrangeText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  OutlineBoldNoMarginButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 29,
    paddingRight: 29,
    marginTop: 20,
    marginBottom: 8,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  OutlineBoldNoMarginText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "bold",
  },
  courseMktOutlineBoldNoMarginButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 29,
    paddingRight: 29,
    marginTop: 20,
    marginBottom: 8,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#ffffff",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
  },
  courseMktOutlineBoldNoMarginText: {
    color: "#F0493E",
    fontFamily: "Graphik-Bold-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
  },
  MoreSeriesOutlineBoldButton: {
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 20,
    marginBottom: 8,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#ffffff00",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    height: 25,
    borderRadius: 4,
  },
  MoreSeriesOutlineBoldText: {
    color: "#F0493E",
    fontFamily: "Graphik-Regular-App",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "800",
  },
  SolidRightJustifiedButton: {
    paddingVertical: 6,
    paddingHorizontal: 29,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    alignSelf: "flex-end",
    cursor: "pointer",
  },
  SolidRightJustifiedText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },
  SolidRightJustifiedMiniButton: {
    paddingVertical: 4,
    paddingHorizontal: 20,
    marginLeft: 0,
    marginRight: 20,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    alignSelf: "flex-end",
    height: 30,
  },
  SolidRightJustifiedMiniText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    lineHeight: 16,
    fontWeight: "600",
  },
  SolidRightJustifiedTopMiniButton: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 29,
    paddingRight: 29,
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 10,
    //   color:"#F0493E",
    backgroundColor: "#F0493E",
    borderWidth: 1,
    borderColor: "#F0493E",
    shadowOffset: { height: 0, width: 0 },
    shadowOpacity: 0,
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  SolidRightJustifiedTopMiniText: {
    color: "#ffffff",
    fontFamily: "Graphik-Regular-App",
    fontSize: 16,
    padding: 10,
    fontWeight: "600",
  },

  // Media Query Desktop Tablet
  "@media (min-width: 481) and (max-width: 768)": {
    SolidCreateAccountButton: {
      alignSelf: "flex-end",
    },
    SolidCreateAccountOneStoryButton: {
      alignSelf: "flex-end",
    },
    SolidRightMarginButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 25,
    },
    SolidRightMarginOneStoryButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 25,
    },
    SolidButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: 125,
    },
    SolidOneStoryButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: 125,
    },
    SolidText: {
      width: "100%",
    },
    SolidProfileButton: {
      position: "static",
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidProfileText: {
      width: "100%",
    },
    SolidProfileOneStoryButton: {
      position: "static",
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidProfileOneStoryText: {
      width: "100%",
    },
    SolidProfileNameButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: 200,
    },
    SolidProfileNameText: {
      width: "100%",
    },
    SolidAboutMeButton: {
      width: 130,
    },
    SolidAboutMeOneStoryButton: {
      width: 130,
    },
    CourseSideBarFirstButton: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 10,
    },
    CourseSideBarButton: {
      marginLeft: 0,
      marginRight: 0,
    },
    CourseTransparentBoldOrangeButton: {
      marginLeft: 0,
      marginRight: 0,
      justifyContent: "center",
    },
    CourseHomeButton: {
      width: "50%",
      alignSelf: "center",
    },
    TransparentActivityCourseButton: {
      marginBottom: 0,
    },
    courseActivityTransparentRegularBlackButton: {
      marginBottom: 0,
    },
    courseCardSolidText: {
      textAlign: "center",
      paddingRight: 0,
      paddingLeft: 0,
    },
    AdminSmallOutlineButton: {
      paddingLeft: 3,
      paddingRight: 3,
      paddingTop: 3,
      paddingBottom: 3,
    },
    SolidSignIn2Button: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 60,
      paddingRight: 60,
      marginLeft: 0,
      marginRight: 0,
      alignSelf: "center",
    },
    SolidSignIn2OneStoryButton: {
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 60,
      paddingRight: 60,
      marginLeft: 0,
      marginRight: 0,
      alignSelf: "center",
    },
  },
  // Media Query Mobile
  "@media (min-width: 320px) and (max-width: 480px)": {
    SolidRightMarginButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      textAlign: "center",
    },
    SolidRightMarginOneStoryButton: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      textAlign: "center",
    },
    SolidRightMarginText: {
      width: "100%",
    },
    SolidRightMarginOneStoryText: {
      width: "100%",
    },
    SolidProfileDeleteButton: {
      marginLeft: 0,
      textAlign: "center",
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: "center",
    },
    SolidProfileDeleteOneStoryButton: {
      marginLeft: 0,
      textAlign: "center",
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: "center",
    },
    SolidButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidOneStoryButton: {
      position: "static",
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidText: {
      width: "100%",
    },
    SolidProfileButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidProfileOneStoryButton: {
      position: "static",
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidProfileNameButton: {
      textAlign: "center",
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%",
    },
    SolidProfileText: {
      width: "100%",
    },
    SolidProfileOneStoryText: {
      width: "100%",
    },
    TransparentBoldOrangeMapButton: {
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 20,
      marginLeft: 10,
      marginRight: 10,
      // color:"#ffffff",
      backgroundColor: "#ffffff00",
      borderWidth: 0,
      borderColor: "#ffffff",
      shadowOffset: { height: 0, width: 0 },
      shadowOpacity: 0,
    },
    TransparentBoldOrangeMapText: {
      color: "#F0493E",
      fontFamily: "Graphik-Bold-App",
      fontSize: 16,
      padding: 10,
      fontWeight: "bold",
    },

    TransparentRegularOrangeButton: {
      paddingTop: 10,
      paddingBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      // color:"#ffffff",
      backgroundColor: "#ffffff00",
      borderWidth: 0,
      borderColor: "#ffffff",
      shadowOffset: { height: 0, width: 0 },
      shadowOpacity: 0,
      alignSelf: "center",
    },
    SolidRightJustifiedButton: {
      alignSelf: "center",
      width: "100%",
    },
    SolidRightJustifiedText: {
      width: "100%",
      textAlign: "center",
    },
    OutlineSmallButton: {
      marginLeft: 0,
      marginBottom: 10,
      justifyContent: "center",
    },
    ProfileSmallButton: {
      marginLeft: 0,
      marginBottom: 10,
      justifyContent: "center",
    },
    SolidAboutMeButton: {
      justifyContent: "center",
    },
    SolidAboutMeOneStoryButton: {
      justifyContent: "center",
    },
    CourseTransparentBoldOrangeButton: {
      justifyContent: "center",
      marginRight: 0,
      marginLeft: 0,
    },
    TransparentActivityCourseButton: {
      marginBottom: 5,
    },
    courseActivityTransparentRegularBlackButton: {
      marginBottom: 5,
    },
    SolidSignIn2Button: {
      alignSelf: "center",
    },
    SolidSignIn2OneStoryButton: {
      alignSelf: "center",
    },
  },
  // Media Query Desktop Large Tablet
  "@media (min-width: 769) and (max-width: 1024)": {
    CourseSideBarFirstButton: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 5,
    },
    CourseSideBarButton: {
      marginLeft: 0,
      marginRight: 0,
    },
    CourseHomeButton: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    CourseTransparentBoldOrangeButton: {
      marginLeft: 0,
      marginRight: 0,
    },
    CourseTransparentBoldOrangeText: {
      padding: 0,
    },
    TransparentActivityCourseButton: {
      marginRight: 13,
      marginBottom: 5,
    },
    courseActivityTransparentRegularBlackButton: {
      marginRight: 13,
      marginBottom: 5,
    },
    AdminSmallOutlineButton: {
      marginLeft: 0,
    },
    // Media Query Mobile
    "@media (min-width: 320px) and (max-width: 480px)": {
      SolidRightMarginButton: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        textAlign: "center",
      },
      SolidRightMarginText: {
        width: "100%",
      },
      SolidRightMarginOneStoryButton: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        textAlign: "center",
      },
      SolidRightMarginOneStoryText: {
        width: "100%",
      },
      SolidProfileDeleteButton: {
        marginLeft: 0,
        textAlign: "center",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: "center",
      },
      SolidProfileDeleteOneStoryButton: {
        marginLeft: 0,
        textAlign: "center",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: "center",
      },
      SolidButton: {
        textAlign: "center",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      },
      SolidOneStoryButton: {
        position: "static",
        textAlign: "center",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      },
      SolidText: {
        width: "100%",
      },
      SolidProfileButton: {
        textAlign: "center",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      },
      SolidProfileOneStoryButton: {
        position: "static",
        textAlign: "center",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      },
      SolidProfileNameButton: {
        textAlign: "center",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
      },
      SolidProfileText: {
        width: "100%",
      },
      SolidProfileOneStoryText: {
        width: "100%",
      },
      SolidRightJustifiedButton: {
        alignSelf: "center",
        width: "100%",
      },
      SolidRightJustifiedText: {
        width: "100%",
        textAlign: "center",
      },
      OutlineSmallButton: {
        marginLeft: 0,
        marginBottom: 10,
        justifyContent: "center",
      },
      ProfileSmallButton: {
        marginLeft: 0,
        marginBottom: 10,
        justifyContent: "center",
        width: "100%",
      },
      SolidAboutMeButton: {
        justifyContent: "center",
      },
      SolidAboutMeOneStoryButton: {
        justifyContent: "center",
      },
      CourseTransparentBoldOrangeButton: {
        justifyContent: "center",
        marginRight: 0,
        marginLeft: 0,
      },
      TransparentActivityCourseButton: {
        marginBottom: 5,
      },
      courseActivityTransparentRegularBlackButton: {
        marginBottom: 5,
      },
      TransparentBoldOrangeMapButton: {
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        alignSelf: "center",
        paddingTop: 0,
      },
    },
  },
}
