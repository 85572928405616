import React from "react"
import { StyleSheet, Text, View } from "react-native"
export default function MyAccountOrganization() {
  return (
    <View>
      <Text>Your Organization</Text>
    </View>
  )
}
const style = StyleSheet.create({})
