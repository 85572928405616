/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7b6acfbf-d55c-46e9-a0de-d3bf264f8fca",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fe30GuOOt",
    "aws_user_pools_web_client_id": "68v5dgb5s96v9lf87cjfpeqmn9",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://uaixja4mvfe37gwyffy4qwez4m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://y214sfi50m.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "jcmobileAPI",
            "endpoint": "https://zi91ox9f72.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "26ed2c1ca2164bad96971e89ce704143",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "26ed2c1ca2164bad96971e89ce704143",
            "region": "us-east-1"
        }
    },
    "aws_content_delivery_bucket": "jc-mobile-20190818234527-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3uxxql9osbe4a.cloudfront.net",
    "aws_user_files_s3_bucket": "jc-profile20190809190418-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "jcMap-dev": {
                        "style": "VectorEsriLightGrayCanvas"
                    }
                },
                "default": "jcMap-dev"
            },
            "search_indices": {
                "items": [
                    "jcPeople-dev"
                ],
                "default": "jcPeople-dev"
            }
        }
    },
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "FACEBOOK",
        "GOOGLE"
    ]
};


export default awsmobile;
